import { IEmployeeNode } from '../../../common/model/graphql-types';

interface IEmployeeStats {
  pve: {
    atk: number;
    critical: number;
    def: number;
    evade: number;
    hit: number;
    hp: number;
  };
  pvp: {
    atk: number;
    critical: number;
    def: number;
    evade: number;
    hit: number;
    hp: number;
  };
}

export const StatCalculator = (
  unitObj: IEmployeeNode,
  requiredLevel: number,
  includeLoyaltyBonus = false
): IEmployeeStats => {
  if (
    !unitObj ||
    !unitObj.stats ||
    !unitObj.stats.perLevel ||
    !unitObj.stats.base
  ) {
    return;
  }

  let multiplier = 1.3;
  if (requiredLevel > 99) {
    multiplier = multiplier + (requiredLevel - 100) * 0.01;
    multiplier = parseFloat(multiplier.toFixed(2));
  }

  let loyaltyMultiplier = 1;
  if (includeLoyaltyBonus) {
    loyaltyMultiplier = 1.02;
  }

  return {
    pve: {
      atk: Math.floor(
        (unitObj.stats.base.atk +
          (requiredLevel - 1) * unitObj.stats.perLevel.atk) *
          multiplier *
          loyaltyMultiplier
      ),
      critical: Math.floor(
        (unitObj.stats.base.critical +
          (requiredLevel - 1) * unitObj.stats.perLevel.critical) *
          multiplier *
          loyaltyMultiplier
      ),
      def: Math.floor(
        (unitObj.stats.base.def +
          (requiredLevel - 1) * unitObj.stats.perLevel.def) *
          multiplier *
          loyaltyMultiplier
      ),
      evade: Math.floor(
        (unitObj.stats.base.evade +
          (requiredLevel - 1) * unitObj.stats.perLevel.evade) *
          multiplier *
          loyaltyMultiplier
      ),
      hit: Math.floor(
        (unitObj.stats.base.hit +
          (requiredLevel - 1) * unitObj.stats.perLevel.hit) *
          multiplier *
          loyaltyMultiplier
      ),
      hp: Math.floor(
        (unitObj.stats.base.hp +
          (requiredLevel - 1) * unitObj.stats.perLevel.hp) *
          multiplier *
          loyaltyMultiplier
      )
    },
    pvp: {
      atk: Math.floor(
        (unitObj.stats.base.atk +
          (requiredLevel - 1) * unitObj.stats.perLevel.atk * 0.1) *
          multiplier *
          loyaltyMultiplier
      ),
      critical: Math.floor(
        (unitObj.stats.base.critical +
          (requiredLevel - 1) * unitObj.stats.perLevel.critical) *
          multiplier *
          loyaltyMultiplier
      ),
      def: Math.floor(
        (unitObj.stats.base.def +
          (requiredLevel - 1) * unitObj.stats.perLevel.def * 0.1) *
          multiplier *
          loyaltyMultiplier
      ),
      evade: Math.floor(
        (unitObj.stats.base.evade +
          (requiredLevel - 1) * unitObj.stats.perLevel.evade) *
          multiplier *
          loyaltyMultiplier
      ),
      hit: Math.floor(
        (unitObj.stats.base.hit +
          (requiredLevel - 1) * unitObj.stats.perLevel.hit) *
          multiplier *
          loyaltyMultiplier
      ),
      hp: Math.floor(
        (unitObj.stats.base.hp +
          (requiredLevel - 1) * unitObj.stats.perLevel.hp * 0.1) *
          multiplier *
          loyaltyMultiplier
      )
    }
  };
};
