export const SpecialitiesArray = [
  { label: 'Any Speciality', value: 'all', description: '' },
  { label: 'Barrier', value: 'Barrier', description: 'Gives shields to self' },
  {
    label: 'Battery',
    value: 'Battery',
    description:
      'Increase resource recovery, allowing you to field characters faster'
  },
  { label: 'Buffer', value: 'Buffer', description: 'Makes teammates stronger' },
  {
    label: 'Crowd Control',
    value: 'CC',
    description:
      'Can stun/silence/knockback etc, making enemies stand like fools and do nothing'
  },
  { label: 'Debuffer', value: 'Debuffer', description: 'Makes enemies weaker' },
  {
    label: 'Detect Stealth',
    value: 'Detect Stealth',
    description: 'Can detect stealthed units'
  },
  {
    label: 'Dispeller',
    value: 'Dispeller',
    description: 'Can remove buffs from enemies'
  },
  {
    label: 'DoT',
    value: 'DoT',
    description: 'Specializes in doing damage over time'
  },
  {
    label: 'ETB',
    value: 'ETB',
    description:
      'Enter-the-battlefield. Does something beneficial when deployed'
  },
  {
    label: 'Executor',
    value: 'Executor',
    description: 'Can kill targets on low HP'
  },
  { label: 'Healer', value: 'Healer', description: 'Heal teammates' },
  {
    label: 'Healblock',
    value: 'Healblock',
    description: 'Blocks or hinders healing'
  },
  {
    label: 'Immortality',
    value: 'Immortality',
    description: 'Can prevent himself from dying or grant the status to allies'
  },
  {
    label: 'Offtank',
    value: 'Offtank',
    description: 'Has enough HP or EVA to fight in the frontline and survive'
  },
  {
    label: 'Paratrooper',
    value: 'Paratrooper',
    description: 'Can deploy anywhere on the battlefield'
  },
  {
    label: 'Perfect Evasion',
    value: 'Perfect Evasion',
    description:
      'Has the ability to temporarily or permanently evade all incoming attacks'
  },
  {
    label: 'Retribution',
    value: 'Retribution',
    description: "Character's death triggers a skill or effect"
  },
  { label: 'Selfhealer', value: 'Selfhealer', description: 'Can heal himself' },
  {
    label: 'Shielder',
    value: 'Shielder',
    description: 'Gives shields to teammates'
  },
  {
    label: 'Summoner',
    value: 'Summoner',
    description:
      'Summons monsters and other things that do the fighting for them'
  },
  {
    label: 'True Damage',
    value: 'True Damage',
    description: 'Does damage that bypasses defences'
  }
];
